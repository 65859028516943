import React, { Component } from "react";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Layout from "../components/layout";
import Lightbox from "react-images";
import Button from "../components/button";
import Slider from "react-slick";
import { Link } from "gatsby";
import MarkdownRenderer from "react-markdown-renderer";

const slugify = require("slugify");

class SingleReference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      lightboxIsOpen: false,
      lang: this.props.pageContext.lang,
      lightboxTitle: this.props.pageContext.data.title,
      data: this.props.pageContext.data,
      references: this.props.pageContext.references,
      breadcrumbs: this.props.pageContext.breadcrumbs,
      footerMenu: this.props.pageContext.footerMenu,
      menu: this.props.pageContext.menu,
    };
  }

  handleLightBoxOpen = currentImage => {
    this.setButtonsHeight();
    this.setState({ currentImage, lightboxIsOpen: true });
    setTimeout(() => {
      const header = document.querySelector('#lightboxBackdrop [class^="header"] span');
      if (header) {
        header.innerHTML = this.state.lightboxTitle;
      }
    }, 100);
  };

  closeLightbox = () => this.setState({ lightboxIsOpen: false });

  setButtonsHeight = () => {
    setTimeout(() => {
      const buttons = document.querySelectorAll('[class^="arrow"]');
      const images = document.querySelectorAll('[class*="imageLoaded"]');
      if (images && images.length > 0) {
        const height = images[0].height;
        buttons.map = Array.prototype.map;
        buttons.map(element => {
          element.style.height = `${height}px`;
          element.style.opacity = `1`;
          return element;
        });
      }
    }, 300);
  };

  render() {
    const {
        breadcrumbs,
        currentImage,
        lightboxIsOpen,
        references,
        lang,
        menu,
        footerMenu,
        data: { referenceScanCaption, title, name, hrefLang, logo, description, referenceScan, realizations_relation },
      } = this.state,
      image = {
        src: referenceScan,
        title: referenceScanCaption,
      },
      referencesSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };
    return (
      <Layout menu={menu} footerMenu={footerMenu} title={`${name} - Posadzki Przemysłowe Polska`}>
        <Lightbox
          images={[image]}
          isOpen={lightboxIsOpen}
          onClickPrev={() => this.gotoPrevious(currentImage, image)}
          onClickNext={() => this.gotoNext(currentImage, image)}
          showCloseButton={true}
          onClose={this.closeLightbox}
          currentImage={currentImage}
          backdropClosesModal={true}
        />
        <div className="bg-gradient">
          <Header lang={lang} hrefLang={hrefLang} />
          <Breadcrumbs data={breadcrumbs} lang={lang} />
          <div className="main-wrapper-md">
            <section className="reference-container">
              <article className="reference-content">
                <header className="section-header red-line">
                  <h2>
                    {title} <strong>{name}</strong>
                  </h2>
                </header>
                <div className="referent-logotype">
                  {logo && <img src={logo.replace('["', "").replace("]", "")} alt="" />}
                </div>
                <MarkdownRenderer markdown={description} />
                <div className="reference-gallery">
                  {image && image.src && (
                    <div className="image-box">
                      <div className="overlay" onClick={() => this.handleLightBoxOpen(0)} />
                      {image.src && <img src={image.src} alt={image.title} />}
                      <button className="btn-zoom" onClick={() => this.handleLightBoxOpen(0)} type="button">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="31.207"
                          height="31.207"
                          viewBox="0 0 31.207 31.207"
                        >
                          <g id="Group_284" data-name="Group 284" transform="translate(-244.5 -424.5)">
                            <rect
                              id="Rectangle_1257"
                              data-name="Rectangle 1257"
                              width="4.243"
                              height="9.899"
                              rx="1"
                              transform="translate(265 448) rotate(-45)"
                              fill="none"
                              stroke="#db0316"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              strokeWidth="1"
                            />
                            <circle
                              id="Ellipse_34"
                              data-name="Ellipse 34"
                              cx="11.5"
                              cy="11.5"
                              r="11.5"
                              transform="translate(245 425)"
                              fill="none"
                              stroke="#db0316"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              strokeWidth="1"
                            />
                            <circle
                              id="Ellipse_35"
                              data-name="Ellipse 35"
                              cx="9.5"
                              cy="9.5"
                              r="9.5"
                              transform="translate(247 427)"
                              fill="none"
                              stroke="#db0316"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              strokeWidth="1"
                            />
                            <path
                              id="Path_466"
                              data-name="Path 466"
                              d="M267,446l-1.5-1.5a.707.707,0,0,0-1,0h0a.707.707,0,0,0,0,1L266,447"
                              fill="none"
                              stroke="#db0316"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              strokeWidth="1"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              </article>
              <aside className="other-realizations">
                {realizations_relation && realizations_relation.length > 0 && (
                  <h3 className="red-line line-md">
                    Wykonane <strong>realizacje</strong>
                  </h3>
                )}
                <div className="other-realizations-wrapper">
                  {realizations_relation &&
                    realizations_relation.length > 0 &&
                    realizations_relation.map(({ title, place, realizations_list }, index) => (
                      <div className="other-realization-box" key={index}>
                        <h4>
                          <strong>{title}</strong> - {place}
                        </h4>
                        <MarkdownRenderer markdown={realizations_list} />
                        <Button
                          text={"Szczegóły realizacji"}
                          href={`/pl/realizacje/${slugify(title, {
                            replacement: "-",
                            remove: null,
                            lower: true,
                          })}`}
                        />
                      </div>
                    ))}
                </div>
              </aside>
            </section>
          </div>
        </div>
        <div className="bg-gradient">
          <div className="main-wrapper-md">
            <section className="reference-slider">
              <Slider {...referencesSettings}>
                {references.map(({ id, logo, name }) => (
                  <Link
                    key={id}
                    to={`/${lang}/${hrefLang.find(item => item.lang === lang).slug}/${slugify(name, {
                      replacement: "-",
                      remove: null,
                      lower: true,
                    })}`}
                  >
                    {logo && <img src={logo.replace('["', "").replace("]", "")} alt={name} />}
                    {!logo && <img src={require("../images/references/philips.png")} alt="" />}
                  </Link>
                ))}
              </Slider>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default SingleReference;
